






import { Component, Mixins } from 'vue-mixin-decorator';
import { Prop } from 'vue-property-decorator';
import OttWidget from './OttWidget.vue';
import WebServiceConnectorMixin from './WebServiceConnectorMixin';
import {Components, Utilities} from 'ottuxlib';
import OttawaGatewayNav = Components.OttawaGatewayNav;
import MenuLinks = Utilities.MenuLink;
import {AxiosResponse} from 'axios';

declare module 'vue/types/vue' {
    interface Vue {
        gateways: string;
        gatewayPath: string;
        gatewayElementId: string;
    }
}

// Create union type of mixins.
interface IGatewayComponent extends WebServiceConnectorMixin {}

/**
 * Ottawa Header Widget
 * Wraps the Ottawa Header component in a Ottawa Widget component for shared scope CSS.
 */
@Component({ components: { OttWidget, OttawaGatewayNav } })
export default class OttGatewayWidget extends Mixins<IGatewayComponent>(WebServiceConnectorMixin) {
    @Prop({ default: '/api/gateway/index' }) apiPath!: string;
    public widgetComponent = 'OttawaGatewayNav';
    private menuLinks: MenuLinks[] = [];
    private Id: string = 'ottawa-gateways';

    // Parse the response into menu links.
    public parseResponse(response: AxiosResponse<MenuLinks[]>) {
        if (typeof response !== "undefined" && response !== null
            && typeof response.data !== "undefined" && response.data !== null) {
            this.menuLinks = response.data;
        }
    }

    // Parse the properties. If gateways are provided, use them. If not make a REST call to get the gateways.
    public async created() {
        // Check for locally provided Gateways.
        if (typeof this.$root.gateways !== 'undefined' && this.$root.gateways !== null) {
            try {
                this.menuLinks = JSON.parse(this.$root.gateways);
            } catch(error) {
                console.error(error.message);
            }
        } else {
            // If not local gateways, call the web service.
            await this.makeWebServiceCall(this.apiPath);
        }
    }

    public mounted() {
        // Set the gateway element id.
        if (typeof this.$root.gatewayElementId !== 'undefined' && this.$root.gatewayElementId !== null) {
            this.Id = this.$root.gatewayElementId;
        }
    }
}
