


















import Vue from 'vue';
import Component from 'vue-class-component';
import OttWidget from './OttWidget.vue';
import {Components, Utilities} from 'ottuxlib';
import OttawaHeader = Components.OttawaHeader;
import MenuLinks = Utilities.MenuLink;

declare module 'vue/types/vue' {
    interface Vue {
        skipToAlertId: string;
        skipToContentId: string;
        extraLinks: string;
        searchNameAttr: string;
        searchAction: string;
        gatewayId: string;
        languageLink: string;
        menuLabel: string;
        homeLinkUrl: string;
        homeLinkTitle: string;
        searchResultsId: string;
        gatewayEvents: string;
    }
}

/**
 * Ottawa Header Widget
 * Wraps the Ottawa Header component in a Ottawa Widget component for shared scope CSS.
 */
@Component({ components: { OttWidget, OttawaHeader } })
export default class OttHeaderWidget extends Vue {
      public widgetComponent = 'OttawaHeader';
      private menuLinks: MenuLinks[] = [];
      private gwElementId = '';
      private searchTarget = 'https://ottawa.ca/search';
      private searchFieldName = 'searchFields';
      private skipToAlert = process.env.NODE_ENV === 'development' ? 'content' : '';
      private skipToContent = 'content';
      private languageUrl = '/fr';
      private lgMenuLabel = 'Sign-in';
      private homeUrl = 'https://ottawa.ca';
      private homeTitle = 'Home';
      private searchControls = '';
      private useGatewayEvents = false;

    public mounted() {
        // Set the gateway element id.
        if (typeof this.$root.gatewayId !== 'undefined' && this.$root.gatewayId !== null) {
            this.gwElementId = this.$root.gatewayId;
        }

        // set the flag signaling the header to listen to gateway events
        if (typeof this.$root.gatewayEvents !== 'undefined' && this.$root.gatewayEvents !== null) {
            this.useGatewayEvents = (this.$root.gatewayEvents.toLowerCase() === 'true');
        }

        // Set the skip to site-wide alert element id. By design, it may not be present
        // if no site-wide alert is currently shown.
        if (typeof this.$root.skipToAlertId !== 'undefined' && this.$root.skipToAlertId !== null) {
            this.skipToAlert = this.$root.skipToAlertId;
        }

        // Set the skip to main content element id.
        if (typeof this.$root.skipToContentId !== 'undefined' && this.$root.skipToContentId !== null) {
            this.skipToContent = this.$root.skipToContentId;
        }
        // Validate the skip to main content element id.
        if (document && !document.getElementById(this.skipToContent)) {
            console.warn('Could not find the skip to content id.');
        }

        // Set the menu links
        if (typeof this.$root.extraLinks !== 'undefined' && this.$root.extraLinks !== null) {
            try {
                  this.menuLinks = JSON.parse(this.$root.extraLinks);
            } catch(error) {
                  console.error(error.message);
            }
        }

        // Set the search target
        if (typeof this.$root.searchAction !== 'undefined' && this.$root.searchAction !== null) {
            this.searchTarget = this.$root.searchAction;
        }

        // Set the search field name
        if (typeof this.$root.searchNameAttr !== 'undefined' && this.$root.searchNameAttr !== null) {
            this.searchFieldName = this.$root.searchNameAttr;
        }

        // Set the language link URL.
        if (typeof this.$root.languageLink !== 'undefined' && this.$root.languageLink !== null) {
            this.languageUrl = this.$root.languageLink;
        }

        // Set the menu label.
        if (typeof this.$root.menuLabel !== 'undefined' && this.$root.menuLabel !== null) {
            this.lgMenuLabel = this.$root.menuLabel;
        }

        // Set the home url.
        if (typeof this.$root.homeLinkUrl !== 'undefined' && this.$root.homeLinkUrl !== null) {
            this.homeUrl = this.$root.homeLinkUrl;
        }

        // Set the home title.
        if (typeof this.$root.homeLinkTitle !== 'undefined' && this.$root.homeLinkTitle !== null) {
            this.homeTitle = this.$root.homeLinkTitle;
        }

        // Set the search controls attribute.
        if (typeof this.$root.searchResultsId !== 'undefined' && this.$root.searchResultsId !== null) {
            this.searchControls = this.$root.searchResultsId;
        }
    }
}
